import Lifestyle1Template from '@/modules/questionnaire/components/steps/questionnaire/oxidative-stress/lifestyle-1/Lifestyle1Template';

import { BOOLEAN } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/OxidativeStress/Lifestyle1/Lifestyle1Template',
  component: Lifestyle1Template
};

const createStory = props => () => ({
  components: { Lifestyle1Template },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><lifestyle1-template v-bind="$options.storyProps" /></div>'
});

export const base = createStory({
  cigaretteIntake: BOOLEAN.YES,
  alcoholIntake: BOOLEAN.YES,
  regularExercise: BOOLEAN.YES
});

export const negative = createStory({
  cigaretteIntake: BOOLEAN.NO,
  alcoholIntake: BOOLEAN.NO,
  regularExercise: BOOLEAN.NO
});

export const unselected = createStory({
  cigaretteIntake: '',
  alcoholIntake: '',
  regularExercise: ''
});
